@use "../../../premmio/public/styles/breakpoints";

#podcastList {
  display: flex;

  figure {
    flex: 1 1 auto;
    max-width: 20rem;
    text-align: center;

    h3 {
      font-size: var(--fs5);
      margin-bottom: 0.5rem;
    }

    img {
      width: 100%;
    }

    + figure {
      margin-left: 1rem;
    }
  }
}

@media (width > breakpoints.$lg) {
  #podcasts {
    article {
      flex: 0 1 auto;
      max-width: breakpoints.$md;
    }

    aside {
      flex: 0 1 auto;
      max-width: breakpoints.$lg;
    }
  }
}
